var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:_vm.isBordered ? 'border-light-secondary' : ''},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"lg","variant":_vm.taskType(_vm.task.content_type).variant}},[_c('i',{staticClass:"card-icon",class:_vm.taskType(_vm.task.content_type).icon})])],1),_c('div',{staticClass:"w-100 d-flex flex-column"},[_c('div',{staticClass:" d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"text-muted font-weight-bolder "},[_vm._v(_vm._s(_vm.taskType(_vm.task.content_type).title))]),_c('h6',{staticClass:"font-weight-bolder small-gap-bottom"},[_vm._v(" "+_vm._s(_vm.task.name)+" ")])]),_c('div',[(_vm.task.type === 'exam')?_c('b-badge',{attrs:{"variant":"light-info"}},[_vm._v(" "+_vm._s(_vm.task.time)+" Menit ")]):_vm._e()],1)]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.task.description)+" ")])],1)]),_c('div',{staticClass:"d-flex align-items-center pt-1 mt-1 justify-content-between border-top"},[(_vm.task.type === 'submission')?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-muted"},[_vm._v(" Batas Pengerjaan : ")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.toHumanDate(_vm.task.end_date))+" ("+_vm._s(_vm.toHumanTime(_vm.task.end_date))+") ")])]):_vm._e(),(_vm.task.type === 'exam')?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-muted"},[_vm._v(" Kuis Dimulai : ")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.toHumanDate(_vm.task.start_date))+" ("+_vm._s(_vm.toHumanTime(_vm.task.start_date))+") ")])]):_vm._e(),(_vm.isContentCreator)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"d-flex align-items-center small-gap-right",attrs:{"size":"sm","to":{
          name: _vm.checkAuthorizeRole('classroom-detail-exam-edit'),
          params: {
            id: _vm.classroomId,
            contentId: _vm.contentId,
            taskId: _vm.task.id,
          },
        },"variant":"warning"}},[_c('i',{staticClass:"regular-icon align-middle ti ti-pencil "})]),_c('b-button',{staticClass:"d-flex align-items-center small-gap",attrs:{"size":"sm","to":{
          name: 'classroom-detail-exam-generate-quiz',
          params: {
            id: _vm.classroomId,
            contentId: _vm.contentId,
            taskId: _vm.task.id,
          },
        },"variant":"danger"}},[_c('i',{staticClass:"regular-icon align-middle ti ti-trash "})])],1):_vm._e(),(!_vm.isContentCreator)?_c('div',[(!_vm.task.is_done)?_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"to":{
          name: _vm.checkAuthorizeRole(
            _vm.task.type === 'submission'
              ? 'classroom-detail-exam-submission'
              : 'classroom-detail-exam-quiz-overview'
          ),
          params: {
            id: _vm.classroomId,
            contentId: _vm.contentId,
            taskId: _vm.task.id,
            contentType: _vm.task.content_type,
          },
        },"variant":"outline-primary"}},[_vm._v(" Detail "),_c('i',{staticClass:"regular-icon align-middle ti ti-chevron-right small-gap-left"})]):_vm._e(),(_vm.task.is_done)?_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-success","to":{
          name: _vm.checkAuthorizeRole(
            _vm.task.type === 'submission'
              ? 'classroom-detail-exam-submission-result'
              : 'classroom-detail-exam-quiz-result'
          ),
          params: {
            id: _vm.classroomId,
            contentId: _vm.contentId,
            taskId: _vm.task.id,
          },
        }}},[_c('i',{staticClass:"regular-icon align-middle ti ti-check small-gap-right"}),_vm._v(" Lihat Hasil ")]):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }