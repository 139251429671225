<template>
  <b-card :class="isBordered ? 'border-light-secondary' : ''">
    <div class="d-flex">
      <div class="mr-1">
        <b-avatar rounded="lg" :variant="taskType(task.content_type).variant">
          <i class="card-icon" :class="taskType(task.content_type).icon" />
        </b-avatar>
      </div>
      <div class="w-100 d-flex flex-column">
        <div class=" d-flex justify-content-between">
          <div class="d-flex flex-column ">
            <span class="text-muted font-weight-bolder ">{{
              taskType(task.content_type).title
            }}</span>
            <h6 class="font-weight-bolder small-gap-bottom">
              {{ task.name }}
            </h6>
          </div>
          <div>
            <b-badge v-if="task.type === 'exam'" variant="light-info">
              {{ task.time }} Menit
            </b-badge>
          </div>
        </div>

        <b-card-text>
          {{ task.description }}
        </b-card-text>
      </div>
    </div>
    <div
      class="d-flex align-items-center pt-1 mt-1 justify-content-between border-top"
    >
      <div v-if="task.type === 'submission'" class="d-flex flex-column">
        <span class="text-muted">
          Batas Pengerjaan :
        </span>
        <span class="font-weight-bolder">
          {{ toHumanDate(task.end_date) }} ({{ toHumanTime(task.end_date) }})
        </span>
      </div>

      <div v-if="task.type === 'exam'" class="d-flex flex-column">
        <span class="text-muted">
          Kuis Dimulai :
        </span>
        <span class="font-weight-bolder">
          {{ toHumanDate(task.start_date) }} ({{
            toHumanTime(task.start_date)
          }})
        </span>
      </div>

      <div v-if="isContentCreator" class="d-flex justify-content-end">
        <b-button
          size="sm"
          class="d-flex align-items-center small-gap-right"
          :to="{
            name: checkAuthorizeRole('classroom-detail-exam-edit'),
            params: {
              id: classroomId,
              contentId: contentId,
              taskId: task.id,
            },
          }"
          variant="warning"
        >
          <i class="regular-icon align-middle ti ti-pencil " />
        </b-button>
        <b-button
          size="sm"
          class="d-flex align-items-center small-gap"
          :to="{
            name: 'classroom-detail-exam-generate-quiz',
            params: {
              id: classroomId,
              contentId: contentId,
              taskId: task.id,
            },
          }"
          variant="danger"
        >
          <i class="regular-icon align-middle ti ti-trash " />
        </b-button>
      </div>

      <div v-if="!isContentCreator">
        <b-button
          v-if="!task.is_done"
          :to="{
            name: checkAuthorizeRole(
              task.type === 'submission'
                ? 'classroom-detail-exam-submission'
                : 'classroom-detail-exam-quiz-overview'
            ),
            params: {
              id: classroomId,
              contentId: contentId,
              taskId: task.id,
              contentType: task.content_type,
            },
          }"
          class="d-flex align-items-center"
          variant="outline-primary"
        >
          Detail
          <i
            class="regular-icon align-middle ti ti-chevron-right small-gap-left"
          />
        </b-button>

        <b-button
          v-if="task.is_done"
          class="d-flex align-items-center"
          variant="outline-success"
          :to="{
            name: checkAuthorizeRole(
              task.type === 'submission'
                ? 'classroom-detail-exam-submission-result'
                : 'classroom-detail-exam-quiz-result'
            ),
            params: {
              id: classroomId,
              contentId: contentId,
              taskId: task.id,
            },
          }"
        >
          <i class="regular-icon align-middle ti ti-check small-gap-right" />
          Lihat Hasil
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  taskType,
  localizeISODate,
  toHumanDate,
  toHumanTime,
  validateStartDate,
} from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

import { BCard, BButton, BCardText, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BCardText,
    BAvatar,
    BBadge,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    isBordered: {
      type: Boolean,
    },
    isContentCreator: {
      type: Boolean,
    },
  },
  data() {
    return {
      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
    }
  },
  methods: {
    taskType,
    toHumanDate,
    toHumanTime,
    localizeISODate,
    validateStartDate,
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-body {
  min-height: 160px;
}
</style>
